import React from 'react'
import Error404 from "../Error404";
import Navbar from "../navbar";
import Footer from "../Footer";

const Error = () => {
  return (
    <>
      <Navbar />
      <Error404 />
      <Footer />
    </>
  )
}

export default Error
