import React from 'react'
import Navbar from '../navbar';
import Services from '../Services';
import Footer from '../Footer';


const Service = () => {
  return (
    <div>
      <Navbar/>
      <Services/>
      <Footer/>
    </div>
  )
}

export default Service
